import { not_null_or_undefined } from '../utilities';
import downloadjs from "downloadjs";

const WIMHOME_API_SERVER_URL = 'https://api.wimonitor.cloud/api/';
// const WIMHOME_API_SERVER_URL='https://www.cbh24.it/api/';
const WIMHOME_API_OS_STRING = 'web';
export const WIMHOME_API_CLIENT_VERSION = '0.1.0';

export const INSTALLATION_MODE_NORMAL = 0;
export const INSTALLATION_MODE_DISABLED = 1;
export const INSTALLATION_MODE_MAINTENANCE = 2;
export const INSTALLATION_MODE_MAINTENANCE_ALARM_TEST = 3;

export const INSTALLATION_STATUS_OBJID_VAR = 'OB10VA1';

export const INSTALLATION_ALERT_STATUS_NO_ALARM = 0;
export const INSTALLATION_ALERT_STATUS_WARNING = 1;
export const INSTALLATION_ALERT_STATUS_ALARM = 2;
export const INSTALLATION_ALERT_STATUS_DISCONNECTED = 3;

export const ALERT_TYPE_ALARM = 1;
export const ALERT_TYPE_WARNING = 2;
export const ALERT_TYPE_INSTALLATION = 3;
export const ALERT_TYPE_TECH_ALARM = 4;
export const ALERT_TYPE_TECH_WARNING = 5;

export const CASE_DESCRIPTION_TYPE_ALARM = 1
export const CASE_DESCRIPTION_TYPE_WARNING = 2
export const CASE_DESCRIPTION_TYPE_TECH = 3
export const CASE_DESCRIPTION_TYPE_CALL = 4
export const CASE_DESCRIPTION_TYPE_JOURNAL = 5
export const CASE_DESCRIPTION_TYPE_PROG_CALL = 6

export const CASE_ENTRY_TYPE_OPEN = 1;
export const CASE_ENTRY_TYPE_TAKE = 2;
export const CASE_ENTRY_TYPE_CLOSE = 3;
export const CASE_ENTRY_TYPE_EXPIRE = 4;
export const CASE_ENTRY_TYPE_REOPEN = 5;
export const CASE_ENTRY_TYPE_JOURNAL = 6;
export const CASE_ENTRY_TYPE_PROG_CALL = 7;

export const JOURNAL_ENTRY_TYPE_NORMAL = 1
export const JOURNAL_ENTRY_TYPE_CAREGIVER = 2
export const JOURNAL_ENTRY_TYPE_PROG_CALL = 3

export const JOURNAL_PROG_CALL_RESPONSE_HAS_REPLIED = "has-replied"
export const JOURNAL_PROG_CALL_RESPONSE_HAS_NOT_REPLIED = "has-not-replied"

export class UnauthorizedError extends Error {
  constructor(message, code) {
    super(message);
    this.name = 'Unauthorized';
    this.code = code;
  }
}

export class BadRequestError extends Error {
  constructor(message, code) {
    super(message);
    this.name = 'Bad Request';
    this.code = code;
  }
}

function replacer(key, value) {
  // Filtering out properties
  if (value === null) {
    return undefined;
  }
  return value;
}

export class WiMHomeAPI {

  constructor(hostname = WIMHOME_API_SERVER_URL) {
    this.hostname = hostname;
    this.useCookie = false;
    this.useRememberMeCookie = false;
    this.jwt = null;
    this.deviceId = null;
    this.language = null;
    this.timeoutTimer = null;
    this.timeoutCallback = null;
    this.timeoutCallbackMargin = 60;
    this.tokenUpdateCallback = null;
  }

  getUseCookie() {
    return this.useCookie;
  }

  setUseCookie(useCookie) {
    this.useCookie = useCookie;
  }

  getLanguage() {
    return this.language;
  }

  setLanguage(lang) {
    this.language = lang;
  }

  getJWT() {
    // console.log("getJWT")
    if (!this.useCookie) {
      // console.log("JWT: " + this.jwt)
      return this.jwt;
    }

    return null;
  }

  setJWT(jwt, deviceId = null, language = null, timeoutTime = null) {
    this.useCookie = false;
    this.useRememberMeCookie = false;
    this.jwt = jwt;

    if (this.timeoutTime !== null) {
      this.timeoutTime = timeoutTime;
    }

    if (deviceId !== null) {
      this.deviceId = deviceId;
    }

    if (language !== null) {
      this.language = language;
    }
  }

  setTimeoutCallback(callback, timeoutTime, existingTokenTimestamp = null) {
    this.timeoutCallback = callback;
    this.timeoutTime = timeoutTime;
    if(existingTokenTimestamp) {
      if(this.timeoutTimer) {
        clearTimeout(this.timeoutTimer);
        this.timeoutTimer = null;
      }
      if (this.timeoutCallback) {
        let nextTimeout = (this.timeoutTime - this.timeoutCallbackMargin) * 1000 - (new Date() - existingTokenTimestamp ?? new Date());
        if (nextTimeout <= 0) {
          nextTimeout = (this.timeoutTime - 30) * 1000;
        }
        this.timeoutTimer = setTimeout(() => {
          this.timeoutTimer = null;
          this.timeoutCallback();
        }, nextTimeout);
      }
    }
  }

  setTimeoutCallbackMargin(margin) {
    this.timeoutCallbackMargin = margin;
  }

  setTokenUpdateCallback(callback) {
    this.tokenUpdateCallback = callback;
  }

  restartTimeout(sessionUpdated) {
    if (sessionUpdated) {
      clearTimeout(this.timeoutTimer);
      this.timeoutTimer = null;
      if (this.timeoutCallback) {
        let nextTimeout = (this.timeoutTime - this.timeoutCallbackMargin) * 1000;
        if (nextTimeout <= 0) {
          nextTimeout = (this.timeoutTime - 30) * 1000;
        }
        this.timeoutTimer = setTimeout(() => {
          this.timeoutTimer = null;
          this.timeoutCallback();
        }, nextTimeout);
      }
    }
  }

  cancelTimeout() {
    clearTimeout(this.timeoutTimer);
    this.timeoutTimer = null;
  }

  generateHeader(doNotAddBearer, skipContentType = false) {
    let reqHeaders = new Headers();
    if(!skipContentType)
      reqHeaders.append('Content-Type', 'application/json');
    reqHeaders.append('Client-OS', WIMHOME_API_OS_STRING);
    reqHeaders.append('Client-Version', WIMHOME_API_CLIENT_VERSION);
    reqHeaders.append('Accept-Language', this.language);
    reqHeaders.append('DeviceId', this.deviceId);
    reqHeaders.append('Accept', 'application/json');
    if (!doNotAddBearer && this.jwt != null) {
      reqHeaders.append('Authorization', 'Bearer ' + this.jwt);
    }
    return reqHeaders;
  }

  generateOptions(method = 'GET', body = null, doNotAddBearer = this.useCookie, skipContentType = false) {
    let options = {
      method: method,
      headers: this.generateHeader(doNotAddBearer, skipContentType),
      //    mode: 'no-cors',
      mode: 'cors',
      //    mode: 'same-origin',
      cache: 'no-store',
      credentials: 'include',
      redirect: 'manual',
      referrer: 'no-referrer',
      referrerPolicy: 'no-referrer',
    };
    if (body !== null) options.body = body;
    return options;
  }

  kickSession(response) {
    if (!this.useCookie && response?.headers?.get('Authorization') !== null) {
      this.jwt = response.headers.get('Authorization').replace('Bearer ', '');
    }
    const isSessionUpdate = response?.headers?.get('Session-Updated') === 'true';
    this.restartTimeout(isSessionUpdate);
    if(isSessionUpdate && this.tokenUpdateCallback) {
      this.tokenUpdateCallback();
    }
  }

  authenticateAPI(
    username,
    password,
    clientDeviceId,
    language,
    setCookie,
    timeoutCallback = null,
    timeoutCallbackMargin = 60,
    tokenUpdateCallback = null
  ) {
    this.useCookie = setCookie;
    this.deviceId = clientDeviceId;
    this.language = language;
    this.timeoutTime = 0;
    this.timeoutCallback = timeoutCallback;
    this.timeoutCallbackMargin = timeoutCallbackMargin;
    this.tokenUpdateCallback = tokenUpdateCallback;

    const loginRequestBodyObj = { username: username, password: password };
    let requestApiUrl = `${this.hostname}sign-in`;
    if (this.useCookie) {
      requestApiUrl += '?setCookie=true';
    }
    let loginRequest = new Request(requestApiUrl);
    const loginOptions = this.generateOptions('POST', JSON.stringify(loginRequestBodyObj), true);
    return fetch(loginRequest, loginOptions)
      .then((response) => {
        if (response.status === 200) {
          if (!this.useCookie) {
            this.jwt = response.headers.get('Authorization').replace('Bearer ', '');
          }
          this.tokenUpdateCallback();
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error('User login error: ' + response.statusText);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        if (this.timeoutCallback) {
          this.timeoutTime = responseObject.sessionDuration;
        }
        this.restartTimeout(true);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  keepAlive() {
    let requestApiUrl = `${this.hostname}auth/keep-alive`;
    let request = new Request(requestApiUrl);
    const reqOptions = this.generateOptions('POST', '');
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error('Keep alive error: ' + response.statusText);
        }
      })
      .catch(($e) => {
        throw $e;
      });
  }

  signOut() {
    let requestApiUrl = `${this.hostname}auth/sign-out`;
    let request = new Request(requestApiUrl);
    const reqOptions = this.generateOptions('POST', '');
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 204) {
          if (!this.useCookie) {
            this.jwt = null;
          }
          this.cancelTimeout();
          return true;
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error('Sign-out error: ' + response.statusText);
        }
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getMqttToken(requestJwt) {
    let requestString = `${this.hostname}auth/mqtt/get_temp_token`;

    if (requestJwt) requestString = requestString + '?jwt=true';
    let tokenRequest = new Request(requestString);
    const tokenReqOptions = this.generateOptions();
    return fetch(tokenRequest, tokenReqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error('Token request failed: ' + response.statusText);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationList() {
    // console.log("getInstallationList");
    let request = new Request(`${this.hostname}auth/installations`);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error('Installations list request failed: ' + response.statusText);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationListDetails(instSerial) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else {
          throw new Error(`Installations detail request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationAlerts(instSerial) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/alerts`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Installations alerts list request for installation ${instSerial} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationGuests(instSerial) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/guests`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Installations guests list request for installation ${instSerial} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationGuest(instSerial, guestId) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/guests/${guestId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Installations guest request for installation ${instSerial} - ${guestId} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  postInstallationGuest(instSerial, guest) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/guests`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('POST', JSON.stringify(guest));
    let badRequest = false;
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 201) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else if (response.status === 400) {
          badRequest = true;
          return response.json();
        } else {
          throw new Error(`Guest post request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        if(badRequest) {
          throw new BadRequestError(responseObject.technicalMessage);
        }
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  patchInstallationGuest(instSerial, guestId, guest) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/guests/${guestId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('PATCH', JSON.stringify(guest));
    let badRequest = false;
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else if (response.status === 400) {
          badRequest = true;
          return response.json();
        } else {
          throw new Error(
            `Guest patch request for installation ${instSerial} - ${guestId} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        if(badRequest) {
          throw new BadRequestError(responseObject.technicalMessage);
        }
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  deleteInstallationGuest(instSerial, guestId) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/guests/${guestId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('DELETE');
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 204) {
          this.kickSession(response);
          return true;
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Installations guest delete request for installation ${instSerial} - ${guestId} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationContacts(instSerial) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/contacts`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
        .then((response) => {
          if (response.status === 200) {
            this.kickSession(response);
            return response.json();
          } else if (response.status === 401 || response.status === 403) {
            throw new UnauthorizedError(response.statusText, response.status);
          } else {
            throw new Error(
                `Installations contacts list request for installation ${instSerial} failed: ${response.statusText}`
            );
          }
        })
        .then((responseObject) => {
          // console.log(responseObject);
          return responseObject;
        })
        .catch(($e) => {
          throw $e;
        });
  }

  getInstallationContact(instSerial, contactId) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/contacts/${contactId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
        .then((response) => {
          if (response.status === 200) {
            this.kickSession(response);
            return response.json();
          } else if (response.status === 401 || response.status === 403) {
            throw new UnauthorizedError(response.statusText, response.status);
          } else {
            throw new Error(
                `Installations contact request for installation ${instSerial} - ${contactId} failed: ${response.statusText}`
            );
          }
        })
        .then((responseObject) => {
          // console.log(responseObject);
          return responseObject;
        })
        .catch(($e) => {
          throw $e;
        });
  }

  postInstallationContact(instSerial, contact) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/contacts`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('POST', JSON.stringify(contact));
    let badRequest = false;
    return fetch(request, reqOptions)
        .then((response) => {
          if (response.status === 201) {
            this.kickSession(response);
            return response.json();
          } else if (response.status === 401 || response.status === 403) {
            throw new UnauthorizedError(response.statusText, response.status);
          } else if (response.status === 400) {
            badRequest = true;
            return response.json();
          } else {
            throw new Error(`Contact post request for installation ${instSerial} failed: ${response.statusText}`);
          }
        })
        .then((responseObject) => {
          // console.log(responseObject);
          if(badRequest) {
            throw new BadRequestError(responseObject.technicalMessage);
          }
          return responseObject;
        })
        .catch(($e) => {
          throw $e;
        });
  }

  patchInstallationContact(instSerial, contactId, contact) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/contacts/${contactId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('PATCH', JSON.stringify(contact));
    let badRequest = false;
    return fetch(request, reqOptions)
        .then((response) => {
          if (response.status === 200) {
            this.kickSession(response);
            return response.json();
          } else if (response.status === 401 || response.status === 403) {
            throw new UnauthorizedError(response.statusText, response.status);
          } else if (response.status === 400) {
            badRequest = true;
            return response.json();
          } else {
            throw new Error(
                `Contact patch request for installation ${instSerial} - ${contactId} failed: ${response.statusText}`
            );
          }
        })
        .then((responseObject) => {
          // console.log(responseObject);
          if(badRequest) {
            throw new BadRequestError(responseObject.technicalMessage);
          }
          return responseObject;
        })
        .catch(($e) => {
          throw $e;
        });
  }

  deleteInstallationContact(instSerial, contactId) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/contacts/${contactId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('DELETE');
    return fetch(request, reqOptions)
        .then((response) => {
          if (response.status === 204) {
            this.kickSession(response);
            return true;
          } else if (response.status === 401 || response.status === 403) {
            throw new UnauthorizedError(response.statusText, response.status);
          } else {
            throw new Error(
                `Installations contact delete request for installation ${instSerial} - ${contactId} failed: ${response.statusText}`
            );
          }
        })
        .then((responseObject) => {
          // console.log(responseObject);
          return responseObject;
        })
        .catch(($e) => {
          throw $e;
        });
  }

  getInstallationDevices(instSerial, returnHidden, returnSafety, includeTech) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/devices?returnHidden=${
      returnHidden ? 'true' : 'false'
    }&returnSafety=${returnSafety ? 'true' : 'false'}&includeTech=${includeTech ? 'true' : 'false'}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Installations device list request for installation ${instSerial} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationDevice(instSerial, deviceId) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/devices/${deviceId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Devices info request for installation ${instSerial} - ${deviceId} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationDeviceDetails(instSerial, deviceId, markNewEventsVisualized) {
    let requestURL = `${this.hostname}auth/installations/${instSerial}/devices/${deviceId}/details`;
    if (markNewEventsVisualized === true) {
      requestURL = requestURL + '?markNewEventsVisualized=true';
    }
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Device detail request for installation ${instSerial} - ${deviceId} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationDeviceEvents(instSerial, deviceId, startDateTime, endDateTime, markNewEventsVisualized) {
    const requestURL = `${
      this.hostname
    }auth/installations/${instSerial}/devices/${deviceId}/events?markNewEventsVisualized=${
      markNewEventsVisualized === true ? 'true' : 'false'
    }&startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Device events request for installation ${instSerial} - ${deviceId} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationDeviceData(instSerial, deviceId, startDateTime, endDateTime) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/devices/${deviceId}/data?startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Device data request for installation ${instSerial} - ${deviceId} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  patchInstallationDeviceState(instSerial, deviceId, currentValue, skipAlertCaseUpdate) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/devices/${deviceId}${
      skipAlertCaseUpdate ? '?skipAlertCaseUpdate=true' : ''
    }`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions(
      'PATCH',
      JSON.stringify({ id: deviceId, currentValue: currentValue.toString() })
    );
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 204) {
          this.kickSession(response);
          return true;
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Device status change request for installation ${instSerial} - ${deviceId} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationServices(instSerial) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/services`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Services request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationService(instSerial, serviceId) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/services/${serviceId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Service request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  patchInstallationService(instSerial, serviceId, serviceDetail) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/services/${serviceId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('PATCH', JSON.stringify(serviceDetail));
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Service patch request for installation ${instSerial} - ${serviceId} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  postInstallationService(instSerial, serviceDetail) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/services`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('POST', JSON.stringify(serviceDetail));
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 201) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Service post request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  deleteInstallationService(instSerial, serviceId) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/services/${serviceId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('DELETE');
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 204) {
          this.kickSession(response);
          return true;
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Service delete request for installation ${instSerial} - ${serviceId} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getExternalServiceProviders() {
    const requestURL = `${this.hostname}auth/services/external-service-providers`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Services request for extrenal service providers failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getExternalServiceProvider(extServiceProvId) {
    const requestURL = `${this.hostname}auth/services/external-service-providers/${extServiceProvId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Services request for extrenal service provider failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationServiceTemplates() {
    const requestURL = `${this.hostname}auth/services/service-templates`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Service templates list request failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationServiceTemplate(templateId) {
    const requestURL = `${this.hostname}auth/services/service-templates/${templateId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Service template request failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationPhyDevices(instSerial) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/phy_devices`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Physical Devices request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationPhyDeviceDetail(instSerial, phyDeviceId) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/phy_devices/${phyDeviceId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Physical Devices detail request for installation ${instSerial}, device ${phyDeviceId} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationJournal(instSerial, latestFirst, offset, limit, startDateTime, endDateTime, onlyProgCalls) {
    let requestURL = `${this.hostname}auth/installations/${instSerial}/journal`;
    if (latestFirst !== null || offset !== null || limit !== null || startDateTime !== null || endDateTime !== null || onlyProgCalls) {
      requestURL = requestURL + '?latestFirst=' + (latestFirst === false ? 'false' : 'true');
    }
    if (offset !== null) {
      requestURL = requestURL + '&offset=' + offset;
    }
    if (limit !== null) {
      requestURL = requestURL + '&limit=' + limit;
    }
    if (startDateTime !== null) {
      requestURL = requestURL + '&startDateTime=' + startDateTime;
    }
    if (endDateTime !== null) {
      requestURL = requestURL + '&endDateTime=' + endDateTime;
    }
    if (onlyProgCalls === true) {
      requestURL = requestURL + '&onlyProgCalls=true';
    }

    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Journal entries request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationJournalCount(instSerial, startDateTime, endDateTime, onlyProgCalls) {
    let requestURL = `${this.hostname}auth/installations/${instSerial}/journal/count`;
    if (startDateTime !== null) {
      requestURL = requestURL + '?startDateTime=' + startDateTime;
    }
    if (endDateTime !== null) {
      requestURL = requestURL + (startDateTime !== null ? '&' : '?') + 'endDateTime=' + endDateTime;
    }
    if (onlyProgCalls === true) {
      requestURL = requestURL + ((startDateTime !== null || endDateTime != null) ? '&' : '?') + 'onlyProgCalls=true';
    }

    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Journal entries request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject.entries;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationLastJournalEntry(instSerial) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/journal/last`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Last journal entry request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  postInstallationJouralEntry(instSerial, entry) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/journal`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('POST', JSON.stringify(entry));
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 201) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Journal post entry request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationCases(instSerial, includeClosed, latestFirst, orderByLastUpdate, offset, limit, includeJournal, caseType) {
    let requestURL = includeClosed ? `${this.hostname}auth/installations/${instSerial}/cases`
      : `${this.hostname}auth/installations/${instSerial}/open-cases`;
    requestURL = requestURL + '?latestFirst=' + (latestFirst ? 'true' : 'false');
    if (orderByLastUpdate === true) {
      requestURL = requestURL + '&orderByLastUpdate=true';
    }
    if (offset > 0) {
      requestURL = requestURL + `&offset=${offset}`;
    }
    if (limit > 0) {
      requestURL = requestURL + `&limit=${limit}`;
    }
    if (includeJournal) {
      requestURL = requestURL + `&includeJournal=${includeJournal ? 'true' : 'false'}`;
    }
    if(caseType !== null) {
      requestURL = requestURL + `&caseType=${caseType}`;
    }
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Cases request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationCasesNumber(instSerial, includeClosed, includeJournal, caseType) {
    let requestURL = includeClosed ? `${this.hostname}auth/installations/${instSerial}/cases/count`
      : `${this.hostname}auth/installations/${instSerial}/open-cases/count`;
    if (includeJournal) {
      requestURL = requestURL + `?includeJournal=${includeJournal ? 'true' : 'false'}`;
    }
    if(caseType !== null) {
      requestURL = requestURL + `&caseType=${caseType}`;
    }
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Cases request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        if (includeClosed) return responseObject.cases;
        else return responseObject.openCases;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  postInstallationNewCase(instSerial, newCase) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/cases/new`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('POST', JSON.stringify(newCase, replacer));
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 201) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else if (response.status === 400) {
          return response.json();
        } else {
          throw new Error(`Reminder post request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  postInstallationCaseEntry(instSerial, caseId, caseEntry) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/cases/${caseId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('POST', JSON.stringify(caseEntry, replacer));
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 201) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else if (response.status === 400) {
          return response.json();
        } else {
          throw new Error(`Reminder post request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  postInstallationReminderAdd(instSerial, reminder) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminders`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('POST', JSON.stringify(reminder, replacer));
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 201) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else if (response.status === 400) {
          return response.json();
        } else {
          throw new Error(`Reminder post request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  postInstallationReminderReply(instSerial, reminderId, timestamp, reply, username, device) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminders/reply`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions(
      'POST',
      JSON.stringify({
        id: reminderId,
        timestamp: timestamp,
        selectedOptionValue: reply,
        username: username,
        device: device,
      })
    );
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 201) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else if (response.status === 400) {
          return response.json();
        } else {
          throw new Error(`Reminder reply post request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationReminders(instSerial, returnExpired, returnFuture, startDateTime, endDateTime) {
    let requestURL = `${this.hostname}auth/installations/${instSerial}/reminders?returnExpired=${
      returnExpired ? 'true' : 'false'
    }&returnFuture=${returnFuture ? 'true' : 'false'}`;
    if (startDateTime !== null) {
      requestURL = requestURL + '&startDateTime=' + startDateTime;
    }
    if (endDateTime !== null) {
      requestURL = requestURL + '&endDateTime=' + endDateTime;
    }
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Reminders request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getUserReminders(username, returnExpired, returnFuture, startDateTime, endDateTime) {
    let requestURL = `${this.hostname}auth/users/${username}/reminders?returnExpired=${
      returnExpired ? 'true' : 'false'
    }&returnFuture=${returnFuture ? 'true' : 'false'}`;
    if (startDateTime !== null) {
      requestURL = requestURL + '&startDateTime=' + startDateTime;
    }
    if (endDateTime !== null) {
      requestURL = requestURL + '&endDateTime=' + endDateTime;
    }
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Reminders request for user ${username} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationReimnder(instSerial, reminderId) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminders/${reminderId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Reminders request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  patchInstallationReminder(instSerial, reminderId, reminder) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminders/${reminderId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('PATCH', JSON.stringify(reminder, replacer));
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else if (response.status === 400) {
          return response.json();
        } else {
          throw new Error(
            `Reminder patch request for installation ${instSerial} - ${reminderId} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  deleteInstallationReminder(instSerial, reminderId) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminders/${reminderId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('DELETE');
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 204) {
          this.kickSession(response);
          return true;
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else if (response.status === 400) {
          return response.json();
        } else {
          throw new Error(
            `Reminder delete request for installation ${instSerial} - ${reminderId} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  postInstallationReminderActionAdd(instSerial, reminderAction) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminders/actions`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('POST', JSON.stringify(reminderAction));
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 201) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Reminder action post request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationReimnderActions(instSerial) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminders/actions`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Reminder actions request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationReimnderAction(instSerial, reminderActionId) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminders/actions/${reminderActionId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Reminder action request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  patchInstallationReminderAction(instSerial, reminderActionId, reminderAction) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminders/actions/${reminderActionId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('PATCH', JSON.stringify(reminderAction));
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else if (response.status === 400) {
          return response.json();
        } else {
          throw new Error(
            `Reminder action patch request for installation ${instSerial} - ${reminderActionId} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        if (not_null_or_undefined(responseObject.errorCode)) {
          throw new Error(responseObject.userMessage);
        }
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  deleteInstallationReminderAction(instSerial, reminderActionId) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminders/actions/${reminderActionId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('DELETE');
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 204) {
          this.kickSession(response);
          return true;
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else if (response.status === 400) {
          return response.json();
        } else {
          throw new Error(
            `Reminder action delete request for installation ${instSerial} - ${reminderActionId} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationReimnderIcons(instSerial) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminder/icons`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Reminder icons request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationReimnderImages(instSerial) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminder/images`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Reminder images request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  postInstallationReminderImage(instSerial, fileObject) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminder/images`;
    let request = new Request(requestURL);
    let formData = new FormData();
    formData.append('image', fileObject);
    const reqOptions = this.generateOptions('POST', formData, this.useCookie,true);
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 201) {
          this.kickSession(response);
          return true;
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Post reminder image request for installation ${instSerial} - ${fileObject.name} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  deleteInstallationReminderImage(instSerial, filename) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminder/images/${filename}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('DELETE');
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 204) {
          this.kickSession(response);
          return true;
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Delete reminder image request for installation ${instSerial} - ${filename} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationReminderSounds(instSerial) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminder/sounds`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Reminder sounds request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  postInstallationReminderSound(instSerial, fileObject) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminder/sounds`;
    let request = new Request(requestURL);
    let formData = new FormData();
    formData.append('sound', fileObject);
    const reqOptions = this.generateOptions('POST', formData);
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 201) {
          this.kickSession(response);
          return true;
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Post reminder sound request for installation ${instSerial} - ${fileObject.name} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  deleteInstallationReminderSound(instSerial, filename) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminder/sounds/${filename}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('DELETE');
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 204) {
          this.kickSession(response);
          return true;
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Delete reminder sound request for installation ${instSerial} - ${filename} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationReminderRecipients(instSerial) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminder/recipients`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
        .then((response) => {
          if (response.status === 200) {
            this.kickSession(response);
            return response.json();
          } else if (response.status === 401 || response.status === 403) {
            throw new UnauthorizedError(response.statusText, response.status);
          } else {
            throw new Error(`Reminder recipients request for installation ${instSerial} failed: ${response.statusText}`);
          }
        })
        .then((responseObject) => {
          // console.log(responseObject);
          return responseObject;
        })
        .catch(($e) => {
          throw $e;
        });
  }

  getInstallationReminderResultRecipients(instSerial) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reminder/result-recipients`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
        .then((response) => {
          if (response.status === 200) {
            this.kickSession(response);
            return response.json();
          } else if (response.status === 401 || response.status === 403) {
            throw new UnauthorizedError(response.statusText, response.status);
          } else {
            throw new Error(`Reminder result recipients request for installation ${instSerial} failed: ${response.statusText}`);
          }
        })
        .then((responseObject) => {
          // console.log(responseObject);
          return responseObject;
        })
        .catch(($e) => {
          throw $e;
        });
  }

  getInstallationLinks(instSerial) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/links`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
        .then((response) => {
          if (response.status === 200) {
            this.kickSession(response);
            return response.json();
          } else if (response.status === 401 || response.status === 403) {
            throw new UnauthorizedError(response.statusText, response.status);
          } else {
            throw new Error(
                `Installation links request for installation ${instSerial} failed: ${response.statusText}`
            );
          }
        })
        .then((responseObject) => {
//          console.log(responseObject);
          return responseObject;
        })
        .catch(($e) => {
          throw $e;
        });
  }

  patchInstallationLinks(instSerial, links) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/links`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('PATCH', JSON.stringify(links));
    return fetch(request, reqOptions)
        .then((response) => {
          if (response.status === 200) {
            this.kickSession(response);
            return response.json();
          } else if (response.status === 401 || response.status === 403) {
            throw new UnauthorizedError(response.statusText, response.status);
          } else {
            throw new Error(
                `Installation links update for installation ${instSerial} failed: ${response.statusText}`
            );
          }
        })
        .then((responseObject) => {
          // console.log(responseObject);
          return responseObject;
        })
        .catch(($e) => {
          throw $e;
        });
  }

  getInstallationInfosDocs(instSerial) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/infos/docs`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Installation information documents request for installation ${instSerial} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  postInstallationInfoDoc(instSerial, fileObject, linkName, description) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/infos/docs`;
    let request = new Request(requestURL);
    let formData = new FormData();
    formData.append('doc', fileObject);
    if(linkName != null) {
      formData.append('linkName', linkName);
      formData.append('description', description);
    }
    const reqOptions = this.generateOptions('POST', formData, this.useCookie, true);
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 201) {
          this.kickSession(response);
          return true;
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Post information document request for installation ${instSerial} - ${fileObject.name} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  deleteInstallationInfoDoc(instSerial, filename) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/infos/docs/${filename}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('DELETE');
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 204) {
          this.kickSession(response);
          return true;
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Delete information document request for installation ${instSerial} - ${filename} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationUserDisplay(instSerial) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/display_options`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Installation display option request for installation ${instSerial} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  patchInstallationUserDisplay(instSerial, displayOptions) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/display_options`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('PATCH', JSON.stringify(displayOptions));
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(
            `Installation display option update for installation ${instSerial} failed: ${response.statusText}`
          );
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getUserOptions(userId) {
    const requestURL = `${this.hostname}auth/users/${userId}/options`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`User option request for user ${userId} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  patchUserOptions(userId, options) {
    const requestURL = `${this.hostname}auth/users/${userId}/options`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('PATCH', JSON.stringify(options));
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`User option update for user ${userId} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getUserInfo(userId) {
    const requestURL = `${this.hostname}auth/users/${userId}/info`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`User info request for user ${userId} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  patchUserInfo(userId, info) {
    const requestURL = `${this.hostname}auth/users/${userId}/info`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('PATCH', JSON.stringify(info));
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`User info update for user ${userId} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  patchUserPassword(userId, username, oldPassword, newPassword) {
    const requestURL = `${this.hostname}auth/users/${userId}/password`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions(
      'PATCH',
      JSON.stringify({ username: username, oldPassword: oldPassword, newPassword: newPassword })
    );
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return true;
        } else if (response.status === 400 || response.status === 403) {
          return response.json();
        } else if (response.status === 401) {
          return response.json();
        } else {
          throw new Error(`User password update for user ${userId} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getUserSounds(userId) {
    const requestURL = `${this.hostname}auth/users/${userId}/sounds`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`User sounds request for user ${userId} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getAuxCountries() {
    const requestURL = `${this.hostname}auth/aux/countries`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Request for countries failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getAuxTimezones() {
    const requestURL = `${this.hostname}auth/aux/timezones`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Request for timezones failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getAuxLocales(onlySupported) {
    const requestURL = `${this.hostname}auth/aux/locales?onlySupported=${onlySupported ? 'true' : 'false'}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Request for locales failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getAuxAlertCodes() {
    const requestURL = `${this.hostname}auth/aux/alert-codes`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
        .then((response) => {
          if (response.status === 200) {
            this.kickSession(response);
            return response.json();
          } else if (response.status === 401 || response.status === 403) {
            throw new UnauthorizedError(response.statusText, response.status);
          } else {
            throw new Error(`Request for countries failed: ${response.statusText}`);
          }
        })
        .then((responseObject) => {
          // console.log(responseObject);
          return responseObject;
        })
        .catch(($e) => {
          throw $e;
        });
  }

  getContactRoles() {
    const requestURL = `${this.hostname}auth/contact-roles`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
        .then((response) => {
          if (response.status === 200) {
            this.kickSession(response);
            return response.json();
          } else if (response.status === 401 || response.status === 403) {
            throw new UnauthorizedError(response.statusText, response.status);
          } else {
            throw new Error(`Request for contact roles failed: ${response.statusText}`);
          }
        })
        .then((responseObject) => {
          // console.log(responseObject);
          return responseObject;
        })
        .catch(($e) => {
          throw $e;
        });
  }

  getAuxFiscalIdentifierTypes() {
    const requestURL = `${this.hostname}auth/aux/fiscal-identifier-types`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Request for fiscal identifier types failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getAuxFiscalIdentifierType(typeId) {
    const requestURL = `${this.hostname}auth/aux/fiscal-identifier-types/${typeId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Request for fical identifier type ${typeId} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationReports(instSerial) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reports`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
        .then((response) => {
          if (response.status === 200) {
            this.kickSession(response);
            return response.json();
          } else if (response.status === 401 || response.status === 403) {
            throw new UnauthorizedError(response.statusText, response.status);
          } else {
            throw new Error(
                `Installation reports request for installation ${instSerial} failed: ${response.statusText}`
            );
          }
        })
        .then((responseObject) => {
          return responseObject;
        })
        .catch(($e) => {
          throw $e;
        });
  }

  getInstallationReport(instSerial, reportId) {
    const requestURL = `${this.hostname}auth/installations/${instSerial}/reports/${reportId}`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    let contentDisposition = "attachment"
    let contentType = "application/pdf";
    let filename = "report" + reportId + ".pdf";
    return fetch(request, reqOptions)
        .then((response) => {
          if (response.status === 200) {
            this.kickSession(response);
            contentDisposition = response.headers.get('Content-Disposition');
            contentType = response.headers.get('Content-Type');
            if (contentDisposition != null && contentDisposition.length > 0) {
              filename = contentDisposition.split('=')[1].trim();
            }
            return response.blob();
          } else if (response.status === 401 || response.status === 403) {
            throw new UnauthorizedError(response.statusText, response.status);
          } else {
            throw new Error(
                `Installation request for installation ${instSerial} report ${reportId} failed: ${response.statusText}`
            );
          }
        })
        .then((body) => {
          const res = downloadjs(body, filename, contentType);
          console.log(res)
          return true
        })
        .catch(($e) => {
          throw $e;
        });
  }

  postPushToken(token) {
    const requestURL = `${this.hostname}auth/push-token`;
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions('POST', JSON.stringify({ pushToken: token }));
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 204) {
          this.kickSession(response);
          return true;
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Push Token post failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationData(
    instSerial,
    deviceList,
    returnEvents,
    includeTech,
    latestFirst,
    startDateTime,
    endDateTime,
    offset,
    limit,
    decodeData
  ) {
    let requestURL = `${this.hostname}auth/installations/${instSerial}/data?startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
    requestURL =
      requestURL +
      `&offset=${offset}&limit=${limit}&returnEvents=${returnEvents}&latestFirst=${latestFirst}&decodeData=${decodeData}&includeTech=${includeTech}`;
    if (Array.isArray(deviceList)) {
      deviceList.forEach((deviceId) => {
        requestURL = requestURL + `&deviceList%5B%5D=${deviceId}`;
      });
    }
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Data request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationDataNumber(instSerial, deviceList, returnEvents, includeTech, startDateTime, endDateTime) {
    let requestURL = `${this.hostname}auth/installations/${instSerial}/data/count?startDateTime=${startDateTime}&endDateTime=${endDateTime}&includeTech=${includeTech}`;
    requestURL = requestURL + `&returnEvents=${returnEvents}`;
    if (Array.isArray(deviceList)) {
      deviceList.forEach((deviceId) => {
        requestURL = requestURL + `&deviceList%5B%5D=${deviceId}`;
      });
    }
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Data request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject.logsCount;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationEvents(instSerial, deviceList, latestFirst, startDateTime, endDateTime, offset, limit) {
    let requestURL = `${this.hostname}auth/installations/${instSerial}/events?startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
    requestURL = requestURL + `&offset=${offset}&limit=${limit}&latestFirst=${latestFirst}`;
    if (Array.isArray(deviceList)) {
      deviceList.forEach((deviceId) => {
        requestURL = requestURL + `&deviceList%5B%5D=${deviceId}`;
      });
    }
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Data request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject;
      })
      .catch(($e) => {
        throw $e;
      });
  }

  getInstallationEventsNumber(instSerial, deviceList, startDateTime, endDateTime) {
    let requestURL = `${this.hostname}auth/installations/${instSerial}/events/count?startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
    if (Array.isArray(deviceList)) {
      deviceList.forEach((deviceId) => {
        requestURL = requestURL + `&deviceList%5B%5D=${deviceId}`;
      });
    }
    let request = new Request(requestURL);
    const reqOptions = this.generateOptions();
    return fetch(request, reqOptions)
      .then((response) => {
        if (response.status === 200) {
          this.kickSession(response);
          return response.json();
        } else if (response.status === 401 || response.status === 403) {
          throw new UnauthorizedError(response.statusText, response.status);
        } else {
          throw new Error(`Data request for installation ${instSerial} failed: ${response.statusText}`);
        }
      })
      .then((responseObject) => {
        // console.log(responseObject);
        return responseObject.eventsCount;
      })
      .catch(($e) => {
        throw $e;
      });
  }
}

// export const wiMHomeAPIclient = new WiMHomeAPI();
